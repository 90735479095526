<template>
	<div class="h-100">
		<CCard class="h-100">
			<CCardBody class="technician-form">
				<div class="shop__header">
					<h5 v-if="!isUpdate" class="mb-3">
						{{ $t("PaysheetTitle.NewPaysheet") }}
					</h5>
					<h5 v-if="isUpdate" class="mb-3">
						{{ paysheetName }}
					</h5>
				</div>
				<ValidationObserver ref="form">
					<form @submit.prevent="submitForm()">
						<div class="custom">
							<div class="custom__input">
								<!-- paysheet name  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="paysheet_name"
										name="paysheet_name"
										:rules="`requiredExport|max:${PAYSHEET_RULE.NAME}`"
									>
										<div class="form-group required row">
											<label for="type" class="col-md-2 col-12 col-form-label">{{
												$t("PaysheetFormFields.Name")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<input
													id="paysheet_name"
													v-model.trim="paysheetForm.name"
													type="text"
													class="form-control"
													:placeholder="$t('PaysheetFormFields.Name')"
													autocomplete="name"
													:maxlength="PAYSHEET_RULE.NAME"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- paysheet detail  -->
								<div class="form-group position-relative">
									<ValidationProvider v-slot="{ classes }" name="paysheet_detail">
										<div class="form-group row">
											<label for="type" class="col-md-2 col-12 col-form-label">{{
												$t("PaysheetFormFields.Detail")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<CustomTable
													:show-filter-search="false"
													:show-filter-received-time="false"
													:show-search-button="false"
													:show-total-items="false"
													:show-item-per-page="false"
													:recent-filter="recentFilters"
													:total-pages="totalPages"
													:total-items="totalItems"
													:no-query="true"
													:table-name="PAYSHEET_TABLE.WAGE"
													class="table__editor"
												>
													<template #headers>
														<tr>
															<th
																v-for="(header, index1) in headers"
																:key="`${header.title}-${index1}`"
																scope="col"
																:class="`${header.class}`"
															>
																{{ header.title }}
															</th>
														</tr>
													</template>
													<template #body>
														<template v-if="typesOrder && typesOrder.length">
															<tr v-for="item in typesOrder" :key="item.id">
																<td scope="row" class="text-break text-pre-wrap text-center">{{
																	item.id
																}}</td>
																<td scope="row" class="text-break text-pre-wrap text-center fit">
																	<UnitWageItem :id="item.id" :key="item.id" :type="item" />
																</td>
															</tr>
														</template>
														<template v-else>
															<tr class="text-center">
																<td colspan="11" class="fit">
																	{{ $t("Table.NoData") }}
																</td>
															</tr>
														</template>
													</template>
												</CustomTable>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- paysheet editor  -->
								<div class="form-group position-relative">
									<ValidationProvider v-slot="{ classes }" name="paysheet_editor">
										<div class="form-group row">
											<label for="type" class="col-md-2 col-12 col-form-label">{{
												$t("PaysheetFormFields.Editor")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<TableSwitch />
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>

						<CRow class="text-right">
							<CCol>
								<button type="button" class="btn btn-outline-danger" @click="cancel">
									{{ $t("Button.Cancel") }}
								</button>
								<button v-if="!isUpdate" class="px-4 ml-2 btn btn-outline-custom" type="submit">
									{{ $t("Button.Create") }}
								</button>
								<button v-else class="px-4 ml-2 btn btn-outline-custom" type="submit">
									{{ $t("Button.Update") }}
								</button>
							</CCol>
						</CRow>
					</form>
				</ValidationObserver>
			</CCardBody>
		</CCard>
		<CustomModal
			v-if="createError"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="confirm"
		>
			<template #content>{{ messageErr }}</template>
		</CustomModal>
	</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { PAYSHEET_TABLE, PAYSHEET_RULE } from "../../../shared/plugins/constants"
import { SET_STATE_PAYSHEET } from "../store/mutation-types"
const { mapState: typeOrderSate } = createNamespacedHelpers("typeOrder")
const { mapState, mapMutations } = createNamespacedHelpers("paysheet")
export default {
	name: "PaysheetForm",
	components: {
		TableSwitch: () => import("./TableSwitch"),
		UnitWageItem: () => import("./UnitWageItem"),
	},
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
		id: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			PAYSHEET_TABLE,
			PAYSHEET_RULE,
			paysheetForm: {
				name: null,
				id: null,
			},
			headers: [
				{
					title: this.$t("PaysheetFieldTable.Type"),
					class: "text-nowrap text-center fit",
				},
				{
					title: this.$t("PaysheetFieldTable.UnitWage"),
					class: "text-nowrap text-center",
				},
			],
			createError: false,
		}
	},
	computed: {
		...typeOrderSate(["typesOrder", "totalPages", "totalItems", "recentFilters"]),
		...mapState({
			selectedEditor: "selectedEditor",
			orderTypes: "typeOrder",
			messageErr: "messageErr",
			paysheetName: "paysheetName",
			paysheetId: "paysheetId",
		}),
	},
	watch: {
		messageErr(val) {
			if (!val) return

			if (val === "This name has already existed") {
				this.$refs["paysheet_name"].$el.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})

				this.setFormFieldErr("paysheet_name", val)
			} else {
				this.createError = true
				this.$store.commit("set", ["modalShow", true])
			}
		},
		paysheetId(val) {
			if (val) this.setupData()
		},
	},
	created() {
		this.setupData()
	},
	methods: {
		...mapMutations({ SET_STATE_PAYSHEET }),
		setupData() {
			if (this.isUpdate && this.paysheetId) {
				this.paysheetForm = {
					...this.paysheetForm,
					name: this.paysheetName,
					id: this.paysheetId,
				}
			}
		},
		async submitForm() {
			const valid = await this.$refs.form.validate()
			if (!valid) {
				return this.$refs["paysheet_name"].$el.scrollIntoView({
					behavior: "smooth",
					block: "center",
				})
			}

			this.$refs.form.reset()
			this.SET_STATE_PAYSHEET(["messageErr", null])

			const params = {
				name: this.paysheetForm.name,
				editorIds: this.selectedEditor.map(el => el.id),
				orderTypes: this.orderTypes.map(el => {
					return {
						...el,
						typeId: el.id,
					}
				}),
			}

			if (this.isUpdate) {
				params["id"] = this.id
			}

			this.$emit("submit", params)
		},
		cancel() {
			this.$emit("cancel")
		},
		setFormFieldErr(field, err) {
			if (this.$refs[field]) this.$refs[field].setErrors([err])
		},
		confirm() {
			this.SET_STATE_PAYSHEET(["messageErr", null])
			this.createError = false
			this.$store.commit("set", ["modalShow", true])
		},
	},
}
</script>
<style lang="scss" scoped>
.custom__input {
	width: 100%;
}
</style>
