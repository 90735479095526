var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[_c('CCard',{staticClass:"h-100"},[_c('CCardBody',{staticClass:"technician-form"},[_c('div',{staticClass:"shop__header"},[(!_vm.isUpdate)?_c('h5',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("PaysheetTitle.NewPaysheet"))+" ")]):_vm._e(),(_vm.isUpdate)?_c('h5',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.paysheetName)+" ")]):_vm._e()]),_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm()}}},[_c('div',{staticClass:"custom"},[_c('div',{staticClass:"custom__input"},[_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{ref:"paysheet_name",attrs:{"name":"paysheet_name","rules":("requiredExport|max:" + (_vm.PAYSHEET_RULE.NAME))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"type"}},[_vm._v(_vm._s(_vm.$t("PaysheetFormFields.Name")))]),_c('div',{class:['col-12 col-md-10', classes]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.paysheetForm.name),expression:"paysheetForm.name",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"paysheet_name","type":"text","placeholder":_vm.$t('PaysheetFormFields.Name'),"autocomplete":"name","maxlength":_vm.PAYSHEET_RULE.NAME},domProps:{"value":(_vm.paysheetForm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.paysheetForm, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}])})],1),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{attrs:{"name":"paysheet_detail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"type"}},[_vm._v(_vm._s(_vm.$t("PaysheetFormFields.Detail")))]),_c('div',{class:['col-12 col-md-10', classes]},[_c('CustomTable',{staticClass:"table__editor",attrs:{"show-filter-search":false,"show-filter-received-time":false,"show-search-button":false,"show-total-items":false,"show-item-per-page":false,"recent-filter":_vm.recentFilters,"total-pages":_vm.totalPages,"total-items":_vm.totalItems,"no-query":true,"table-name":_vm.PAYSHEET_TABLE.WAGE},scopedSlots:_vm._u([{key:"headers",fn:function(){return [_c('tr',_vm._l((_vm.headers),function(header,index1){return _c('th',{key:((header.title) + "-" + index1),class:("" + (header.class)),attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(header.title)+" ")])}),0)]},proxy:true},{key:"body",fn:function(){return [(_vm.typesOrder && _vm.typesOrder.length)?_vm._l((_vm.typesOrder),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"text-break text-pre-wrap text-center",attrs:{"scope":"row"}},[_vm._v(_vm._s(item.id))]),_c('td',{staticClass:"text-break text-pre-wrap text-center fit",attrs:{"scope":"row"}},[_c('UnitWageItem',{key:item.id,attrs:{"id":item.id,"type":item}})],1)])}):[_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"fit",attrs:{"colspan":"11"}},[_vm._v(" "+_vm._s(_vm.$t("Table.NoData"))+" ")])])]]},proxy:true}],null,true)})],1)])]}}])})],1),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{attrs:{"name":"paysheet_editor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"type"}},[_vm._v(_vm._s(_vm.$t("PaysheetFormFields.Editor")))]),_c('div',{class:['col-12 col-md-10', classes]},[_c('TableSwitch')],1)])]}}])})],1)])]),_c('CRow',{staticClass:"text-right"},[_c('CCol',[_c('button',{staticClass:"btn btn-outline-danger",attrs:{"type":"button"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("Button.Cancel"))+" ")]),(!_vm.isUpdate)?_c('button',{staticClass:"px-4 ml-2 btn btn-outline-custom",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("Button.Create"))+" ")]):_c('button',{staticClass:"px-4 ml-2 btn btn-outline-custom",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("Button.Update"))+" ")])])],1)],1)])],1)],1),(_vm.createError)?_c('CustomModal',{attrs:{"submit-text":_vm.$t('Button.OK'),"is-show-cancel":false},on:{"submit-modal":_vm.confirm},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.messageErr))]},proxy:true}],null,false,3566196471)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }